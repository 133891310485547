import React, { useContext, useState } from 'react';
import { NavContext } from '../../context/NavContext';
import NavLink from './NavLink';

const ProjectLinks = () => {
	const { activeNavLinkId } = useContext(NavContext);
  const [showDropdown, setShowDropdown] = useState(false);

	return (
    <div
      id="dropdownLinkContainer"
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <span
        id={'project'}
        className={activeNavLinkId === 'project' ? 'activeClass' : ''}
      >
        Project
      </span>
      {showDropdown && (
        <div id="dropdownLinks">
          <NavLink key={15} navLinkId={'Roadmap'} scrollToId={'roadmapContainer'} mobileScrollToId={'roadmapContainerMobile'} />
          <NavLink key={16} navLinkId={'Team'} scrollToId={'teamContainer'} mobileScrollToId={'teamContainer'} />
          <NavLink key={17} navLinkId={'FAQ'} scrollToId={'faqContainer'} mobileScrollToId={'faqContainer'} />
        </div>
      )}
    </div>
	);
};

export default ProjectLinks;