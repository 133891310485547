const Footer = () => {
  return (
    <>
      <div className="container">
        <div className="row social-icons-follow">
          <div className="col-xs-2">
            <a href="https://discord.gg/2EZhuPTtQX"
              className="discord social" target="_blank" rel="noreferrer">
              <img src="discord.png" className="login-button-icon social-link" alt="discord icon" />
            </a>
          </div>
          <div className="col-xs-2">
            <a href="https://twitter.com/liveskippers"
              className="twitter social"  target="_blank" rel="noreferrer">
              <img src="twitter.png" className="login-button-icon social-link" alt="twitter icon" />
            </a>
          </div>
          <div className="col-xs-2">
            <a href="https://www.instagram.com/liveskippers/"
              className="instagram social"  target="_blank" rel="noreferrer">
              <img src="instagram.png" className="login-button-icon social-link" alt="instagram icon" />
            </a>
          </div>
          <div className="col-xs-2">
            <a href="https://www.youtube.com/channel/UC2wMEs5l9Bm8a_Z_dS60VMw"
              className="youtube social"  target="_blank" rel="noreferrer">
              <img src="youtube.png" className="login-button-icon social-link" alt="youtube icon" />
            </a>
          </div>
          <div className="col-xs-2">
            <a href="https://t.me/+BSLP5uW76XowZjY0"
              className="telegram social"  target="_blank" rel="noreferrer">
              <img src="telegram.png" className="login-button-icon social-link" alt="telegram icon" />
            </a>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  )
}

export default Footer;