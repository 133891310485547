const Q22022 = () => {
  return (
    <>
      <h5 className="greyish-brown">Q2 2022</h5>
      <ul className="ul-spacing">
        <li>SKIPPERS website launch <span className="small-oval bg-green"></span></li>
        <li>Smart contract audit</li>
        <li>Marketing campaign kickoff <span className="small-oval bg-green"></span></li>
        <li>Complete first land acquisition <span className="small-oval bg-green"></span></li>
        <li>Finalise first community architecture layouts</li>
        <li>Start first site development</li>
      </ul>
    </>
  )
}

export default Q22022;
