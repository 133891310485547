const Team = () => {
  return (
    <div id="teamContainer" className="component-container">
      <div className="row">
        <div className="col-xs-12 pb-0"><h2 className="left-aligned mb-0">Our team</h2></div>
        <div className="col-xs-12 col-sm-6 col-md-4 ptb-2">
          <img src="motorbike-lion.png"
            className="team-lion-image"
            alt="original lion" />
          <p className="bold t-center">Duncan</p>
          <p className="t-center">Creative Director</p>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 ptb-2">
          <img src="dreads-lion.png"
            className="team-lion-image"
            alt="dreads lion" />
          <p className="bold t-center">Camilla</p>
          <p className="t-center">Technical Director</p>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 ptb-2">
          <img src="pink-lion.png"
            className="team-lion-image"
            alt="pink lion" />
          <p className="bold t-center">Henry</p>
          <p className="t-center">Architect</p>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 ptb-2">
          <img src="orange-lion.png"
            className="team-lion-image"
            alt="orange lion" />
          <p className="bold t-center">Daniel</p>
          <p className="t-center">Designer</p>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 ptb-2">
          <img src="original-lion.png"
            className="team-lion-image"
            alt="original lion" />
          <p className="bold t-center">Asilia</p>
          <p className="t-center">Kenya Legal</p>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 ptb-2">
          <img src="grey-hair-lion.png"
            className="team-lion-image"
            alt="grey hair lion" />
          <p className="bold t-center">James</p>
          <p className="t-center">EU Legal</p>
        </div>
      </div>
    </div>
  )
}

export default Team;