import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';

const DianiFacts = () => {
  return (
    <div id="factsContainer" className="component-container">
      <h2 className="left-aligned">First community - Diani, Ukunda</h2>
      <Tabs>
        <TabList>
          <Tab>Scores</Tab>
          <Tab>Digital nomad data</Tab>
          <Tab>Facts</Tab>
          <Tab>Weather</Tab>
          <Tab>Timezones</Tab>
          <Tab>Photos</Tab>
          <Tab>Videos</Tab>
        </TabList>

        <TabPanel>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <img src="scores.png" className="scores-image" alt="diani scores" />
            </div>
            <div className="col-sm-6 col-xs-12">
              <img src="scores-2.png" className="scores-image" alt="diani scores 2" />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <img src="nomad-data-1.png" className="scores-image" alt="nomad data" />
            </div>
            <div className="col-sm-6 col-xs-12">
              <img src="nomad-data-2.png" className="scores-image" alt="nomad data 2" />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <img src="facts-1.png" className="scores-image" alt="nomad facts" />
            </div>
            <div className="col-sm-6 col-xs-12">
              <img src="facts-2.png" className="scores-image" alt="nomad facts 2" />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div className="col-xs-12 col-sm-4"><a href="https://weatherspark.com/" target="_blank" rel="noreferrer"><img src="weather-1.png" className="scores-image" alt="weather spark diani" /></a></div>
            <div className="col-xs-12 col-sm-4"><a href="https://weatherspark.com/" target="_blank" rel="noreferrer"><img src="weather-2.png" className="scores-image" alt="weather spark diani" /></a></div>
            <div className="col-xs-12 col-sm-4"><a href="https://weatherspark.com/" target="_blank" rel="noreferrer"><img src="weather-3.png" className="scores-image" alt="weather spark diani" /></a></div>
            <div className="col-xs-12 col-sm-4"><a href="https://weatherspark.com/" target="_blank" rel="noreferrer"><img src="weather-4.png" className="scores-image" alt="weather spark diani" /></a></div>
            <div className="col-xs-12 col-sm-4"><a href="https://weatherspark.com/" target="_blank" rel="noreferrer"><img src="weather-5.png" className="scores-image" alt="weather spark diani" /></a></div>
            <div className="col-xs-12 col-sm-4"><a href="https://weatherspark.com/" target="_blank" rel="noreferrer"><img src="weather-6.png" className="scores-image" alt="weather spark diani" /></a></div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div className="col-xs-12"><img src="timezonez@3x.png" className="scores-image" alt="timezone" /></div>
          </div>
        </TabPanel>
        <TabPanel>
          {/* <h2>Any content 5</h2> */}
        </TabPanel>
        <TabPanel>
          {/* <h2>Any content 6</h2> */}
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default DianiFacts;