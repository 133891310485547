import NavProvider from "../context/NavContext";
import MintNav from "./Nav/MintNav";

const WhatToDoPage = () => {
  return (
    <>
      <NavProvider>
        <MintNav />
      </NavProvider>
      <div className="container">
        <div className="under-construction-panel">
          <h2>Coming Soon</h2>
          <p className="t-center">We're still working on this page it should be ready by 19/04/2022, please check back later.</p>
        </div>
      </div>
    </>
  )
}

export default WhatToDoPage;