import React, { useContext, useState } from 'react';
import { NavContext } from '../../context/NavContext';
import SocialNavLink from './SocialNavLink';

const SocialLinks = () => {
  const { activeNavLinkId } = useContext(NavContext);
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div
      id="dropdownLinkContainer"
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <span
        id={'socialGood'}
        className={activeNavLinkId === 'socialGood' ? 'activeClass' : ''}
      >
        Social
      </span>
      {showDropdown && (
        <div id="dropdownLinks">
          <SocialNavLink key={18} navLinkId={'Discord'} icon={'discord.png'} link={'https://discord.gg/2EZhuPTtQX'} alt={'discord icon'} />
          <SocialNavLink key={19} navLinkId={'Twitter'} icon={'twitter.png'} link={'https://twitter.com/liveskippers'} alt={'twitter icon'} />
          <SocialNavLink key={20} navLinkId={'Instagram'} icon={'instagram.png'} link={'https://www.instagram.com/liveskippers/'} alt={'instagram icon'} />
          <SocialNavLink key={21} navLinkId={'Youtube'} icon={'youtube.png'} link={'https://www.youtube.com/channel/UC2wMEs5l9Bm8a_Z_dS60VMw'} alt={'youtube icon'} />
          <SocialNavLink key={22} navLinkId={'Telegram'} icon={'telegram.png'} link={'https://t.me/+BSLP5uW76XowZjY0'} alt={'telegram icon'} />
        </div>
      )}
    </div>
  );
};

export default SocialLinks;