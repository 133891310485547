import React from 'react';

const SocialNavLink = ({ navLinkId, icon, link, alt }) => {
  return (
    <span id={navLinkId} className="nav-social-link">
      <a href={link}
        className="discord social" target="_blank" rel="noreferrer">
        <img src={icon} className="login-button-icon social-link" alt={alt} />
      </a>
    </span>
  );
};

export default SocialNavLink;