import ReactTooltip from 'react-tooltip';

const MintPageWalletButton = ({connectWallet, currentAccount, isMobileDevice}) => {
  // const [currentAccount] = useState(
  //   localStorage.getItem('currentAccount') || ''
  // )

  const buttonText = () => {
    let buttonText = '';
    if(currentAccount) {
      buttonText = 'Disconnect your wallet'
    } else if(!currentAccount) {
      buttonText = 'Connect your Wallet'
    }
    return buttonText;
  }

  const buttonDisabled = () => {
    if(currentAccount) {
      return true;
    } else {
      return false;
    }
  }

  const buttonAction = () => {
    if(currentAccount) {
      const newWindow = window.open('https://metamask.zendesk.com/hc/en-us/articles/360059535551-Disconnect-wallet-from-a-dapp', '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } else {
      connectWallet();
    }
  }
  if (isMobileDevice()) {
    const dappUrl = "www.liveskippers.com/mint"; // TODO enter your dapp URL. For example: https://uniswap.exchange. (don't enter the "https://")
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    return (
      <>
        <a href={metamaskAppDeepLink}>
          <button className="cta-button login-button" data-tip data-for={buttonDisabled() ? 'registerTip' : ''}>
            <p className="semi-bold white">{buttonText()}</p>
          </button>
        </a>
        <ReactTooltip id="registerTip" place="top" effect="solid">
          To disconnect your wallet please follow the instructions provided when clicking the button below.
        </ReactTooltip>
      </>
    );
  }

  return (
    <>
      <button onClick={buttonAction} className="cta-button login-button" data-tip data-for={buttonDisabled() ? 'registerTip' : ''}>
        <p className="semi-bold white">{buttonText()}</p>
      </button>
      <ReactTooltip id="registerTip" place="top" effect="solid">
        To disconnect your wallet please follow the instructions provided when clicking the button below.
      </ReactTooltip>
    </>
  )
}

export default MintPageWalletButton;
