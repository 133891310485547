import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AllowlistProvider from './context/AllowlistContext';
import LandingPage from './components/LandingPage';
import MintPage from './components/MintPage';
import WhatToDoPage from './components/WhatToDoPage';
import './App.css';
import './assets/styles/flexboxgrid.min.css';

const App = () => {
  // TODO: still need this??
  // const renderNetworkPrompt = () => (
  //   alert("Hello there, This app is built on the rinkeby testnet and it looks like you are on a different ethereum network. Please switch to the Rinkeby testnet to continue")
  // )

  return (
    <div className="App">
      <AllowlistProvider>
        <BrowserRouter>
          <Routes>
              <Route path='/' element={<LandingPage />} />
              <Route path='/auth/twitter/callback' element={<MintPage callback={'twitter'} />} />
              <Route path='/auth/discord/callback' element={<MintPage callback={'discord'} />} />
              <Route path='/mint' element={<MintPage callback={'none'} />} />
              <Route path='/what-to-do' element={<WhatToDoPage callback={'none'} />} />
              <Route path='*' element={<LandingPage />} />
          </Routes>
        </BrowserRouter>
      </AllowlistProvider>
    </div>
  );
}

export default App;
