import Q12022 from "./Q12022";
import Q12023 from "./Q12023";
import Q22022 from "./Q22022";
import Q242023 from "./Q242023";
import Q32022 from "./Q32022";
import Q42022 from "./Q42022";

const RoadmapMobile = () => {
  return (
    <div className="row component-container roadmapContainerMobile" id="roadmapContainerMobile">
      <div className="col-xs-12">
        <h2 className="left-aligned">Roadmap</h2>

        <Q12022 />
        <Q22022 />
        <Q32022 />
        <Q42022 />
        <img src="jetski-lion.png"
          className="Bitmap"
          alt="orange lion" />
        <Q12023 />
        <Q242023 />
      </div>
    </div>
  )
}

export default RoadmapMobile;