import React, { useState, useEffect } from 'react';
import DropdownNavLink from './DropdownNavLink';
import SocialGoodNavLinks from './SocialGoodNavLinks';
import ProjectLinks from './ProjectLinks';
import SocialLinks from './SocialLinks';
import '../../assets/styles/flexboxgrid.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './Nav.css';
import ConnectWalletButton from '../Shared/ConnectWalletButton';

const Nav = () => {
	const [toggleMenu, setToggleMenu] = useState(false)
	const [screenWidth, setScreenWidth] = useState(window.innerWidth)

	const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

	useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

		return () => {
			window.removeEventListener('resize', changeWidth)
	}

  }, [])

	return (
		<>
			<nav>
				{(screenWidth < 860) && (
					<>
					<div className="logo"><a href="/"><img src="logo.png"
						srcSet="logo@2x.png 2x,
										logo@3x.png 3x"
						alt="logo" className="logo-image margin-0" /></a></div>
					<button onClick={toggleNav} className="menu-link"><img src="hamburger.png" alt="menu" /></button>
					</>
				)}
				{(toggleMenu || screenWidth > 860) && (
					<div className="nav-link-menu">
						{(!toggleMenu) && (
							<div className="logo"><a href="/"><img src="logo.png"
								srcSet="logo@2x.png 2x,
												logo@3x.png 3x"
								alt="logo" className="logo-image margin-0" /></a></div>
						)}
						<DropdownNavLink />
						<SocialGoodNavLinks />
            <ProjectLinks />
            <SocialLinks />
						<ConnectWalletButton pageOrigin={'nav'} />
					</div>
				)}

			</nav>
		</>
	);
};

export default Nav;