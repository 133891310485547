const BuyMembership = () => {
  return (
    <div className="component-container">
      <div className="row">
        <div className="col-xs-12">
          <h2>How to buy your membership</h2>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-xs-12 col-sm-4">
          <img src="metamask.png"
            srcSet="metamask@2x.png 2x,
                    metamask@3x.png 3x"
            className="membership-icon"
            alt="metamask icon" />
          <br />
          <p className="Step-1 p-semi-bold">
            Step 1
          </p>
          <p className="p-greyish-brown t-center membership-step-description">
            Connect your Metamask Wallet
          </p>
        </div>
        <div className="col-xs-12 col-sm-4">
          <img src="card-standard.png"
            className="membership-icon"
            alt="membership icon" />
          <br />
          <p className="Step-1 p-semi-bold">
            Step 2
          </p>
          <p className="p-greyish-brown t-center membership-step-description">
            Choose your desired membership
          </p>
        </div>
        <div className="col-xs-12 col-sm-4">
          <img src="ethereum.png"
            srcSet="ethereum@2x.png 2x,
                    ethereum@3x.png 3x"
            className="membership-icon"
            alt="membership icon" />
          <br />
          <p className="Step-1 p-semi-bold">
            Step 3
          </p>
          <p className="p-greyish-brown t-center membership-step-description">
            Buy your membership with ETH
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p className="nft-guide-description p-black">
            Never bought an NFT before? See our <a className="cerulean underline" target="_blank" rel="noreferrer" href="https://www.liveskippers.com/c/how-to-join-the-skippers-community/">full guide</a>.
          </p>
        </div>
      </div>
    </div>
  )
}

export default BuyMembership;