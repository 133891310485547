const Values = () => {
  return (
    <div id="valuesContainer" className="component-container">
      <div className="row">
        <div className="col-xs-12 col-sm-6 pr-2">
          <h2 className="left-aligned">Values</h2>
          <p className="p-greyish-brown">
            We believe that organisations should account for more than profits.  Beyond good traditional accounting social issues are key and should be measured in how they help and add to the community.<br /><br />
            We are developing a plan with local NGOs to help improve the lives of the local community and help offer better opportunities including digital remote online.<br /><br />
            At our first site we will have immediate volunteer opportunities with included accomodation.
          </p>
        </div>
        <div className="col-xs-6 col-sm-3 mt-6 value-flex-column">
          <div className="row">
            <div className="col-xs-12 container-social-partner">
              <h4 className="t-center no-margin">Social Partner #1</h4>
              <p className="small t-center">To be revealed Q2 2022</p>
            </div>
          </div><br />
          <div className="row">
            <div className="col-xs-12 container-social-partner">
              <h4 className="t-center no-margin">Social Partner #2</h4>
              <p className="small t-center">TBC</p>
            </div>
          </div>
        </div>
        <div className="col-xs-6 col-sm-3 mt-5">
          <img src="pink-lion.png"
            srcSet="pink-lion@2x.png 2x,
                    pink-lion@3x.png 3x"
            className="values-lion"
            alt="pink lion" />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-xs-12 col-sm-6">
          <p className="bold">Digital Skills Volunteer</p>
          <p>Working with a local partner the digital skills volunteer will work to help improve digital skills with yuong local adults.</p>
        </div>
        <div className="col-xs-12 col-sm-6">
          <p className="bold">Social Entrepeneur in Residence</p>
          <p>The SEiR will work on projects that help improve the local area and collaborate with other NGOs</p>
        </div>
      </div>

    </div>
  )
}

export default Values;