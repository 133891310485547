import MembershipCards from "../Shared/MembershipCards";

const Memberships = () => {
  return (
    <div id="utilitiesContainer" className="component-container">
      <div className="row">
        <div className="col-xs-12">
          <h2>Memberships / Utilities</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-11 membership-container membership-container-center">
          <h3 className="no-margin">All SKIPPERS members</h3>
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <img src="skateboard-lion.png"
                className="all-members-lion"
                alt="orange icon" />
            </div>
            <div className="col-sm-4">
              <p className="small bold">General benefits</p>
              <ul>
                <li>30% discount on all food and drinks at all SKIPPERS communities</li>
                <li>30% discount on room rates at all SKIPPERS communities</li>
                <li>Exclusive access to member only rates at partner communities and locations</li>
                {/* <li>A daily beer, mojito or cocktail of the day</li> */}
                <li>Free airport transfers (Up to 50km from the property)</li>
                <li>Priority booking at all properties</li>
              </ul>
            </div>
            <div className="col-sm-4">
              <p className="small bold">Activities</p>
              <ul>
                <li>50% off any further kite rental at all beach properties</li>
                <li>Unlimited mountain bike use</li>
              </ul>
              <p className="small bold">Events</p>
              <ul>
                <li>Free access to in season music events</li>
                <li>Access to themed member only weeks/weekends</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <MembershipCards displayMintButton={false} />
    </div>
  )
}

export default Memberships;