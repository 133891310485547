import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../Shared/Spinner';
import ConnectWalletButton from '../Shared/ConnectWalletButton';
import JoinPreSaleButton from '../Shared/JoinPreSaleButton';
import 'react-toastify/dist/ReactToastify.css';
import ConnectedStatusBadge from './ConnectedStatusBadge';

// TODO: implement setOnAllowList when all requirements met

const AllowList = ({callback}) => {
  const [searchParams] = useSearchParams();
  const [currentAccount] = useState(
    localStorage.getItem('currentAccount') || ''
  )
  const [onAllowList, setOnAllowList] = useState(
    JSON.parse(localStorage.getItem('onAllowList') || 'false')
  )
  const [twitterVerified, setTwitterVerified] = useState(
    JSON.parse(localStorage.getItem('twitterVerified') || 'false')
  )
  const [discordVerified, setDiscordVerified] = useState(
    JSON.parse(localStorage.getItem('discordVerified') || 'false')
  )
  const [discordVerifiedUser, setDiscordVerifiedUser] = useState(
    JSON.parse(localStorage.getItem('discordVerifiedUser') || 'false')
  )
  const [loadingDiscordSpinner, setLoadingDiscordSpinner] = useState(false);
  const [loadingTwitterSpinner, setLoadingTwitterSpinner] = useState(false);
  const [preSale] = useState(
    JSON.parse(localStorage.getItem('preSale') || 'false')
  )
  const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const BEARER_TOKEN = process.env.REACT_APP_BEARER_TOKEN;

  const connectTwitter = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + BEARER_TOKEN,
      }
    };
    const response = await fetch(BASE_URL + "/twitter/request_authentication", requestOptions)
    const data = await response.json();
    if(data.redirect_url !== "") {
      window.location.href = data.redirect_url;
    }
  }

  const getTwitterUser = async () => {
    setLoadingTwitterSpinner(true);
    const token = searchParams.get('oauth_token');
    const verifier = searchParams.get('oauth_verifier');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + BEARER_TOKEN,
      }
    };
    const response = await fetch(BASE_URL + "/twitter/create?oauth_token=" + token + "&oauth_verifier=" + verifier, requestOptions)
    const data = await response.json();
    // here if error
    // setTwitterUser(data.user)
    const requestOptionsVerify = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + BEARER_TOKEN,
      }
    };
    const verifyResponse = await fetch(BASE_URL + "/twitter/verify?address=" + currentAccount + "&username=" + data.user, requestOptionsVerify)
    const verified = await verifyResponse.json();
    if(verified.value) {
      setTwitterVerified(verified.value);
    } else {
      notifyError("Hmmm, it doesn't look like that twitter user is following us. Follow us then try again.")
    }
    setLoadingTwitterSpinner(false);
    window.location.href = '/mint';
    // localStorage.setItem('twitterVerified', verified.value)
  }

  const disconnectTwitter = async () => {
    setTwitterVerified(false);
    setOnAllowList(false);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + BEARER_TOKEN,
      }
    };
    await fetch(BASE_URL + "/twitter/disconnect?address=" + currentAccount, requestOptions)
  }

  const connectDiscord = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + BEARER_TOKEN,
      }
    }
    const response = await fetch(BASE_URL + "/discord/request_authentication", requestOptions)
    const data = await response.json();
    if(data.redirect_url !== "") {
      window.location.href = data.redirect_url;
    }
  }

  const getDiscordUser = async () => {
    setLoadingDiscordSpinner(true);
    const code = searchParams.get('code');
    console.log('searchParas + ', searchParams)
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + BEARER_TOKEN,
      },
      body: JSON.stringify({username: code})
    };
    const response = await fetch(BASE_URL + "/discord/create?code=" + code + "&address=" + currentAccount, requestOptions)
    const data = await response.json();
    if(data.value) {
      if(data.value.user_exists) {
        setDiscordVerified(data.value.user_exists);
        if(data.value.verified === true) {
          setDiscordVerifiedUser(data.value.user_exists);
        } else {
          notifyError("You don't appear to be a verified user in our Discord server. Please join then try again.")
        }
      }
    } else {
      notifyError("You don't appear to be in our Discord server. Please join then try again.")
    }
    setLoadingDiscordSpinner(false);
    window.location.href = '/mint';
  }

  const disconnectDiscord = async () => {
    setDiscordVerified(false);
    setOnAllowList(false);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + BEARER_TOKEN,
      }
    };
    await fetch(BASE_URL + "/discord/disconnect?address=" + currentAccount, requestOptions)
  }

  const walletStatus = () => {
    return (currentAccount ? 'Connected' : 'Disconnected');
  }

  const twitterStatus = () => {
    return (twitterVerified ? 'Following' : 'Disconnected');
  }

  const discordStatus = () => {
    let status = '';
    if(discordVerified && discordVerifiedUser) {
      status = 'Verified user'
    } else if(discordVerified && !discordVerifiedUser) {
      status = 'Not verified user'
    } else if(!discordVerified) {
      status = 'Disconnected'
    }
    return status;
  }

  const presaleStatus = () => {
    return (preSale ? 'In pre-sale' : 'Not in pre-sale');
  }

  const notifyError = (error) => {toast.error(error)};

  useEffect(() => {
    if(callback === 'twitter') {
      getTwitterUser();
    } else if(callback === 'discord') {
      getDiscordUser();
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem('twitterVerified', JSON.stringify(twitterVerified))
    if(twitterVerified && discordVerified && discordVerifiedUser) {
      setOnAllowList(true)
    }
  }, [twitterVerified]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem('discordVerified', JSON.stringify(discordVerified))
    if(twitterVerified && discordVerified && discordVerifiedUser) {
      setOnAllowList(true)
    }
  }, [discordVerified]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem('onAllowList', JSON.stringify(onAllowList))
  }, [onAllowList])

  return (
    <div className="">
      <ToastContainer />
      <h1 className="black t-center">Join our allowlist</h1>
      <p className="t-center">Join our allow list to be able to mint. If you would like to join the pre-sale ask in our <a href="https://discord.gg/Wjm9sRg4w6" target="_blank" rel="noreferrer">Discord server</a></p>
      <br />
      {onAllowList && preSale && (
        <p className="bold t-center mb-30"><div className="oval bg-green"></div>Your wallet is enabled for future minting</p>
      )}
      {onAllowList && !preSale && (
        <p className="bold t-center mb-30"><div className="oval bg-orange"></div>Your wallet is not on the pre-sale list. Request access on the <a href="https://discord.gg/Wjm9sRg4w6" target="_blank" rel="noreferrer">Discord server</a></p>
      )}
      {!onAllowList && (
        <p className="bold t-center mb-30"><div className="oval bg-red"></div>Complete the steps below to join our allowlist</p>
      )}

      <br />
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-5">
          <div className='row'>
            <div className='col-xs-5 step'>
              <h5 className="black no-margin">Step 1</h5>
            </div>
            <div className='col-xs-7 flex-end'>
              <ConnectedStatusBadge status={walletStatus()} />
            </div>
          </div>
          <p className="">Download the <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn/related" target="_blank" rel="noreferrer">Metamask chrome extension</a>. This is a wallet where you will be able to store your membership.</p>
        </div>
        <div className="col-sm-5 flex">
          <ConnectWalletButton pageOrigin={'mint'} />
        </div>
        <div className="col-sm-1"></div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-5">
          <div className='row'>
            <div className='col-xs-5 step'>
              <h5 className="black no-margin">Step 2</h5>
            </div>
            <div className='col-xs-7 flex-end'>
              <ConnectedStatusBadge status={twitterStatus()} />
            </div>
          </div>
          <p className="">Follow us on Twitter <a href="https://twitter.com/liveskippers" target="_blank" rel="noreferrer">@liveskippers</a> and authenticate to validate you are following on Twitter.</p>

        </div>
        <div className="col-sm-5 flex">
          {twitterVerified && (
            <button onClick={disconnectTwitter} className="cta-button login-button">
              <img src="twitter_white.png" className="login-button-icon" alt="twitter icon" />
              <p className="semi-bold white">Disconnect Twitter</p>
            </button>
          )}
          {!twitterVerified && (
            <button onClick={connectTwitter} className="cta-button login-button">
              <img src="twitter_white.png" className="login-button-icon" alt="twitter icon" />
              <p className="semi-bold white">Login with Twitter</p>
              {loadingTwitterSpinner && (
                <Spinner />
              )}
            </button>
          )}
        </div>
        <div className="col-sm-1"></div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-5">
          <div className='row'>
            <div className='col-xs-5 step'>
              <h5 className="black no-margin">Step 3</h5>
            </div>
            <div className='col-xs-7 flex-end'>
              <ConnectedStatusBadge status={discordStatus()} />
            </div>
          </div>
          <p className="">Join our <a href="https://discord.gg/Wjm9sRg4w6" target="_blank" rel="noreferrer">Discord server</a> and authenticate to validate you are in our Discord.</p>
        </div>
        <div className="col-sm-5 flex">
          {discordVerified && (
            <button onClick={disconnectDiscord} className="cta-button login-button">
              <img src="discord_white.png" className="login-button-icon" alt="discord icon" />
              <p className="semi-bold white">Disconnect Discord</p>
            </button>
          )}
          {!discordVerified && (
            <button onClick={connectDiscord} className="cta-button login-button">
              <img src="discord_white.png" className="login-button-icon" alt="discord icon" />
              <p className="semi-bold white">Login with Discord</p>
              {loadingDiscordSpinner && (
                <Spinner />
              )}
            </button>
          )}
        </div>
        <div className="col-sm-1"></div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-5">
          <div className='row'>
            <div className='col-xs-5 step'>
              <h5 className="black no-margin">Step 4</h5>
            </div>
            <div className='col-xs-7 flex-end'>
              <ConnectedStatusBadge status={presaleStatus()} />
            </div>
          </div>
          <p className="">Join the pre-sale list by requesting access on our <a href="https://discord.gg/Wjm9sRg4w6" target="_blank" rel="noreferrer">Discord server</a></p>
        </div>
        <div className="col-sm-5 flex">
          <JoinPreSaleButton />
        </div>
        <div className="col-sm-1"></div>
      </div>
      <br />
      <p className="t-center mb-1"><span className="bold">Note: </span>You must be following the twitter account and be a member of the Discord chat to be able to mint.</p>
    </div>
  )
}

export default AllowList;