import React from 'react';
import NavProvider from '../context/NavContext';
import Nav from './Nav/Nav';
import Introduction from './LandingPage/Introduction';
import BuyMembership from './LandingPage/BuyMembership';
import DigitalArt from './LandingPage/DigitalArt';
import Memberships from './LandingPage/Memberships';
import Giveaways from './LandingPage/Giveaways';
import Roadmap from './RoadMap/Roadmap';
import RoadmapMobile from './RoadMap/RoadmapMobile';
import DianiFacts from './LandingPage/DianiFacts';
import Communities from './LandingPage/Communities';
import Values from './LandingPage/Values';
import ValueEstimate from './LandingPage/ValueEstimate';
import Events from './LandingPage/Events';
import Team from './LandingPage/Team';
import ScrollButton from './LandingPage/ScrollButton';
import FrequentlyAskedQuestions from './LandingPage/FrequentlyAskedQuestions';
import '../assets/styles/flexboxgrid.min.css';
import ImageBanner from './LandingPage/ImageBanner';
import Footer from './Shared/Footer';

const LandingPage = () => {
  return (
    <>
      <NavProvider>
        <Nav />
      </NavProvider>
      <div className="container">
        <Introduction />
        <ImageBanner src={"1-st@3x.webp"} />
        <BuyMembership />
        <DigitalArt />
        <ImageBanner src={"2@3x.webp"} />
        <Memberships />
        <ValueEstimate />
        <Giveaways />
        <ImageBanner src={"3@3x.webp"} />
        <Roadmap />
        <RoadmapMobile />
        <ImageBanner src={"4@3x.webp"} />
        <Communities />
        <DianiFacts />
        <ImageBanner src={"5@3x.webp"} />
        <Events />
        <Values />
        <Team />
        <FrequentlyAskedQuestions />
      </div>
      <ScrollButton />
      <Footer></Footer>
    </>
   
  )
}

export default LandingPage;