const Q12023 = () => {
  return (
    <>
      <h5 className="greyish-brown">Q1 2023</h5>
      <ul className="ul-spacing">
        <li>First member only week as proposed and voted for by members</li>
        <li>Launch events cultural season at first site</li>
        <li>Start development of SKIPPERS 2nd community</li>
      </ul>
    </>
  )
}

export default Q12023;