import React, { useState } from 'react';

const JoinPreSaleButton = () => {
  const [currentAccount] = useState(
    localStorage.getItem('currentAccount') || ''
  )
  const [onAllowList] = useState(
    JSON.parse(localStorage.getItem('onAllowList') || 'false')
  )

  const sendToDiscord = () => {
    const newWindow = window.open('https://discord.gg/Wjm9sRg4w6', '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const presaleDisabled = () => {
    if(currentAccount && onAllowList) {
      return false;
    } else {
      return true;
    };
  };

  return (
    <button onClick={sendToDiscord} className="cta-button login-button" disabled={presaleDisabled()}>
      <p className="semi-bold white">Join pre-sale</p>
    </button>
  )
}

export default JoinPreSaleButton;