const NavWalletButton = ({connectWallet, currentAccount, isMobileDevice}) => {
  const buttonDisabled = () => {
    if(currentAccount) {
      return 'disabled';
    } else {
      return '';
    }
  }

  if (isMobileDevice()) {
    const dappUrl = "www.liveskippers.com/mint"; // TODO enter your dapp URL. For example: https://uniswap.exchange. (don't enter the "https://")
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    return (
      <a href={metamaskAppDeepLink} className="metamask-deeplink">
        <span
          id={16}
          className={"action-button-navlink cerulean " + buttonDisabled()}
        >
          Connect to Wallet
        </span>
      </a>
    );
  }

  return (
    <span
      id={16}
      className={"action-button-navlink cerulean " + buttonDisabled()}
      onClick={connectWallet}
    >
      Connect to Wallet
    </span>
  )
}

export default NavWalletButton;