const Q42022 = () => {
  return (
    <>
      <h5 className="greyish-brown">Q4 2022</h5>
      <ul className="ul-spacing">
        <li>Launch the first community in Diani, Kenya</li>
        <li>Start digital skills volunteer and mentoring programme</li>
        <li>Commence research for SKIPPERS beach club community</li>
        <li>2nd NFT drop for 2nd SKIPPERS community</li>
      </ul>
    </>
  )
}

export default Q42022;