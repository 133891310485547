const ValueEstimate = () => {
  return (
    <>
      <div className="row component-container" id="valueEstimateContainer">
        <div className="col-xs-12">
          <h2 className="left-aligned">Value estimate</h2>
        </div>
        <div className="col-xs-12 col-sm-6">
          <p className="p-greyish-brown">
            Some of our benefits can’t be valued such as priority booking and members only weeks.<br /><br />
            Here is our most conservative estimate for the face value of everything else in our memberships based on an average one month stay in low-season.
          </p>
          <div className="row">
            <div className="col-xs-6">
              <p className="bold">Standard</p>
              <p className="p-greyish-brown">Accomodation - $428</p>
              <p className="p-greyish-brown">Food and drinks - $122</p>
              <p className="p-greyish-brown">Activities - $1,435</p>
              <p className="p-greyish-brown">Airport transfer - $80</p>
              <p className="p-greyish-brown italic">Y1 Total = $2,055</p>
            </div>
            <div className="col-xs-6">
              <p className="bold">Gold</p>
              <p className="p-greyish-brown">Accomodation - $787</p>
              <p className="p-greyish-brown">Food and drinks - $122</p>
              <p className="p-greyish-brown">Activities - $2,660</p>
              <p className="p-greyish-brown">Airport transfer - $80</p>
              <p className="p-greyish-brown italic">Y1 Total = $3,639</p>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6">
          <img src="face-value.png" className="scores-image" alt='' />
        </div>
      {/* </div><br />
      <div className="row"> */}
        <div className="col-xs-12">
          <p className="p-greyish-brown">Estimates are based on room rates compiled on 20th Feb 2022 where $787 was the lowest priced room available with similar amenities via Airbnb. The lowest via Hotels.com was $4,509. See whitepaper for full details. Event ticket values not included.</p>
        </div>
      </div>
    </>
  )
}

export default ValueEstimate;