import React, { useState, useEffect } from 'react';

const DISABLE_MINT_BUTTON = process.env.REACT_APP_DISABLE_MINT_BUTTON;

const MembershipCards = ({ displayMintButton }) => {
  const [mintDisabled, setMintDisabled] = useState(true)

  const disableMintButton = () => {
    if(DISABLE_MINT_BUTTON === "true") {
      setMintDisabled(true)
    } else {
      setMintDisabled(false)
    }
  }

  useEffect(() => {
    disableMintButton()
  }, [])

  return (
    <div className="row membership-row">
      <div className="col-xs-11 col-sm-5 membership-container membership-container-left">
        <div className="">
          <img src="card-standard.png"
            className="membership-card card-true"
            alt="casual card icon" />
          <h3>Standard LION Membership</h3>
          <p className="p-small">400 available</p><br />
          <p className="small bold">Enjoy membership benefits including...</p>
          <ul>
            <li>12 days (total) free accomodation credit</li>
            <li>Late checkout w/baggage storage</li>
          </ul>
          <p className="small bold">Activites</p>
          <ul>
            <li>7 days of kitesurf rental at beach town communities</li>
            <li>7 days of eBike / scooter / motorbike use (125cc/150cc) per year</li>
            <li>One day trip per month visit e.g. Elephant safari, Dolphin tour, Off road mororbiking</li>
          </ul>
          <p className="small bold">Governance</p>
          <ul>
            <li>Propose and vote on themed weeks</li>
            <li>Propose and vote on allocation of community funds derived from resales</li>
          </ul>
          {displayMintButton && (
            <div className="button-container-left">
              <button className="cta-button mint-button" disabled={mintDisabled}>
                <h5>Mint</h5>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="col-xs-11 col-sm-5 membership-container membership-container-right">
        <div className="">
          <img src="card-gold.png"
            className="membership-card card-true"
            alt="true card icon" />
          <h3>Gold LION membership</h3>
          <p className="small">200 available</p><br />
          <p className="small bold">Enjoy membership benefits including...</p>
          <ul>
            <li>Free upgrade to next level of room if available</li>
            <li>30 days (total) free accomodation credit</li>
            <li>Airdrops of spare capacity</li>
            <li>Late checkout 4pm</li>
          </ul>
          <p className="small bold">Activities</p>
          <ul>
            <li>30 days of free kitesurf rental at beach town communities</li>
            <li>14 days of use of 125/150cc motorbikes/scooters per year</li>
            <li>3 days of use of 200/250cc off road motorbikes per year</li>
            <li>Free two day safari per year or equivalent alternative</li>
          </ul>
          <p className="small bold">Governance</p>
          <ul>
            <li>Propose and x3 vote on the themed weeks</li>
            <li>Propose and x3 votes on allocation of community funds</li>
          </ul>
          {displayMintButton && (
            <div className="button-container">
              <button className="cta-button mint-button" disabled={mintDisabled}>
                <h5>Mint</h5>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MembershipCards;