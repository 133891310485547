import { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify';

import '../../assets/styles/flexboxgrid.min.css';
import 'react-toastify/dist/ReactToastify.css';
import ConnectWalletButton from '../Shared/ConnectWalletButton';


const Introduction = () => {
  const [currentAccount] = useState(
    localStorage.getItem('currentAccount') || ''
  )
  const [onAllowList] = useState(
    JSON.parse(localStorage.getItem('onAllowList') || 'false')
  )
  const [preSale] = useState(
    JSON.parse(localStorage.getItem('preSale') || 'false')
  )

  const buttonActionInfo = () => {
    if(currentAccount) {
      if(onAllowList) {
        if(preSale) {
          return <p className="extra-small bold"><span className="oval bg-green"></span>Your wallet is enabled for future minting.</p>
        } else {
          return <p className="extra-small bold"><span className="oval bg-orange"></span>Your wallet is not on the pre-sale list. Request access on the <a href="https://discord.gg/Wjm9sRg4w6" target="_blank" rel="noreferrer">Discord server</a></p>
        }
      } else if (!onAllowList) {
        return <p className="extra-small bold"><span className="oval bg-red"></span>Your wallet is not on the allow list <a href="/mint">click here to join</a></p>
      }
    }
  }

  useEffect(() => {
    localStorage.setItem('currentAccount', currentAccount);
  }, [currentAccount])

  return (
    <div className="row">
      <ToastContainer autoClose={false} />
      <div className="col-sm-6 pr-2">
        <h1>Digital Nomad <br/> Coliving in Africa</h1>
        <span className="Were-creating-a-mag p-greyish-brown">
          We’re creating a magical adventure rich coliving & coworking community in East Africa.
          <br /><br />
          You’ll find an amazing community, with purpose built better than home facilities in a vibrant location. We hope it’s your best nomad experience ever.
          <br /><br />
          Add your wallet to our allowlist for our drop, join our discord.
          <br /><br />
          <span className="text-style-1">Dropping Q3 2022</span>
          <br />
          <div className="row">
            <div className="col-xs-12 col-sm-6 prl-1">
              <ConnectWalletButton pageOrigin={'landing'}  />
            </div>
            <div className="col-xs-2 col-sm-2 flex">
              <a href="https://t.me/+BSLP5uW76XowZjY0"
                className="telegram social margin-auto" target="_blank" rel="noreferrer">
                <img src="telegram.png" className="login-button-icon social-link" alt="telegram icon" />
              </a>
            </div>
            <div className="col-xs-2 col-sm-4 flex">
              <a href="https://twitter.com/liveskippers"
                className="twitter social margin-auto"  target="_blank" rel="noreferrer">
                <img src="twitter.png" className="login-button-icon social-link" alt="twitter icon" />
              </a>
            </div>
            {/* <div className="col-xs-2"></div> */}
          </div>
          {buttonActionInfo()}
        </span>
      </div>
      <div className="col-sm-6 pl-2">
        <img src="original-lion@3x.png"
        className="pirate"
        alt="pirate lion" />
      </div>
      {/* <div className="col-xs-12">
        <br />
        <p className="p-cerulean">
          <a
            href="Skippers Whitepaper v2.6.pdf"
            className="underline cerulean bold"
            download
            target="_blank" rel="noreferrer"
          >Download our full whitepaper here</a></p>
      </div> */}
    </div>
  )
}

export default Introduction;