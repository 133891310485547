import React, {useState} from 'react';
  
const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 400){
      setVisible(true)
    } 
    else if (scrolled <= 400){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <div className="scroll-button-container" style={{display: visible ? 'flex' : 'none'}}>
      <div className="scroll-to-top-button">
        <button onClick={scrollToTop} className="scroll-to-top-button-action">
          <h5>Get started</h5>
        </button>
        <h5 className="black pl-2 scroll-information"><a className="cerulean" target="_blank" rel="noreferrer" href="https://www.liveskippers.com/c/how-to-join-the-skippers-community/">Click here</a> to read more about how to join</h5>
      </div>
    </div>
  );
}
  
export default ScrollButton;