import React, { useState, useContext, useEffect } from 'react';
import { AllowlistContext } from '../../context/AllowlistContext';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { toast } from 'react-toastify';
import MintPageWalletButton from '../MintPage/MintPageWalletButton';
import LandingPageWalletButton from '../LandingPage/LandingPageWalletButton';
import NavWalletButton from '../Nav/NavWalletButton';

const TOKEN = process.env.REACT_APP_FINGERPRINT_TOKEN;

const fpPromise = FingerprintJS.load({
  token: TOKEN
})

const CustomToastWithLink = () => (
  <div>
    You need Metamask installed on your browser to connect your Wallet. Download <a target='_blank' rel="noreferrer" href='https://metamask.io/'>Metamask</a> on your current browser then try again
  </div>
);

function isMobileDevice() {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

const ConnectWalletButton = ({pageOrigin}) => {
  const [currentAccount, setCurrentAccount] = useState(
    localStorage.getItem('currentAccount') || ''
  )

  const { setFingerprintId } = useContext(AllowlistContext);
  const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const BEARER_TOKEN = process.env.REACT_APP_BEARER_TOKEN;

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      return;
    } else {
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      setCurrentAccount(account);
      localStorage.setItem('currentAccount', account);
   
      updateWalletInformation();
    } else {
      if (isMobileDevice()) {
        await connectWallet();
      }
      localStorage.removeItem('currentAccount');
      console.log("No authorized account found")
    }

    
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        toast.error(CustomToastWithLink, {duration: Infinity, id: 'metamaskError'})
        return;
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });

      setCurrentAccount(accounts[0]);
      localStorage.setItem('currentAccount', accounts[0]);
      updateWalletInformation();
      window.location.href = '/mint';
    } catch (error) {
      console.log(error)
    }
  }

  const updateWalletInformation = async () => {
    const fp = await fpPromise;
    const fpResponse = await fp.get();
    const result = await fpResponse.visitorId;
    localStorage.setItem('fingerprintId', result)
    setFingerprintId(result);

    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + BEARER_TOKEN
        }
      }
      const response = await fetch(BASE_URL + "/member/connect_wallet?address=" + currentAccount + "&fingerprint_id=" + result, requestOptions)
      const data = await response.json();
      if(data.twitter_verified !== null) {
        localStorage.setItem('twitterVerified', JSON.stringify(data.twitter_verified))
      }
      if(data.discord_verified !== null) {
        localStorage.setItem('discordVerified', JSON.stringify(data.discord_verified))
      }
      if(data.discord_verified_user !== null) {
        localStorage.setItem('discordVerifiedUser', JSON.stringify(data.discord_verified_user))
      }
      if(data.pre_sale !== null) {
        localStorage.setItem('preSale', JSON.stringify(data.pre_sale))
      }
    } catch {
      console.log('caught error here')
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(pageOrigin === 'mint') {
    return (
      <MintPageWalletButton connectWallet={connectWallet} currentAccount={currentAccount} isMobileDevice={isMobileDevice} />
    )
  } else if(pageOrigin === 'nav') {
    return (
      <NavWalletButton connectWallet={connectWallet} currentAccount={currentAccount} isMobileDevice={isMobileDevice} />
    )
  }
  return (
    <LandingPageWalletButton connectWallet={connectWallet} currentAccount={currentAccount} isMobileDevice={isMobileDevice} />
  )
}

export default ConnectWalletButton;