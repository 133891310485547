import React, { useContext, useState } from 'react';
import { NavContext } from '../../context/NavContext';
import NavLink from './NavLink';

const DropdownNavLink = () => {
	const { activeNavLinkId } = useContext(NavContext);
  const [showDropdown, setShowDropdown] = useState(false);

	return (
    <div
      id="dropdownLinkContainer"
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <span
        id={'NFTs'}
        className={activeNavLinkId === 'NFTs' ? 'activeClass' : ''}
      >
        NFTs
      </span>
      {showDropdown && (
        <div id="dropdownLinks">
          <NavLink key={9} navLinkId={'Art'} scrollToId={'artContainer'} mobileScrollToId={'artContainer'} />
          <NavLink key={10} navLinkId={'Utility'} scrollToId={'utilitiesContainer'} mobileScrollToId={'utilitiesContainer'} />
          <NavLink key={11} navLinkId={'Benefits'} scrollToId={'giveawayContainer'} mobileScrollToId={'giveawayContainer'} />
        </div>
      )}
    </div>
	);
};

export default DropdownNavLink;