const Q242023 = () => {
  return (
    <>
      <h5 className="greyish-brown">Q2 - Q4 2023</h5>
      <ul className="ul-spacing">
        <li>Complete development of 2nd community</li>
        <li>Members launch event at 2nd location</li>
      </ul>
    </>
  )
}

export default Q242023;