const Q32022 = () => {
  return (
    <>
      <h5 className="greyish-brown">Q3 2022</h5>
      <ul className="ul-spacing">
        <li>First NFT drop to pre-sale allow list & public sale</li>
        <li>Listing of SKIPPERS on OpenSea</li>
        <li>Additional benefits commence</li>
        <li>Begin partnering research and discussions</li>
      </ul>
    </>
  )
}

export default Q32022;
