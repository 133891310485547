import React, { useState } from 'react';

export const AllowlistContext = React.createContext();

const AllowlistProvider = ({ children }) => {
  const [onAllowList, setOnAllowList] = useState(false);
  const [twitterVerified, setTwitterVerified] = useState(false);
  const [discordVerified, setDiscordVerified] = useState(false);
  const [currentAccount, setCurrentAccount] = useState('');
  const [fingerprintId, setFingerprintId] = useState('');

	const allowlistValue = {
		onAllowList,
		setOnAllowList,
    twitterVerified,
    setTwitterVerified,
    discordVerified,
    setDiscordVerified,
    currentAccount,
    setCurrentAccount,
    fingerprintId,
    setFingerprintId
	};

	return (
		<AllowlistContext.Provider value={allowlistValue}>{children}</AllowlistContext.Provider>
	);
};

export default AllowlistProvider;