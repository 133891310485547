import Q12022 from "./Q12022";
import Q12023 from "./Q12023";
import Q22022 from "./Q22022";
import Q242023 from "./Q242023";
import Q32022 from "./Q32022";
import Q42022 from "./Q42022";


const Roadmap = () => {
  return (
    <div className="row component-container roadmapContainer" id="roadmapContainer">
      <div className="col-xs-12 col-sm-4">
        <div className="column">
          <div className="col">
            <h2 className="left-aligned">Roadmap</h2>

            <Q12022 />
          </div>
          <div className="col">
            <Q42022 />
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-4">
        <div className="column">
          <div className="col-xs-0 col col-roadmap-padding-9"></div>
          <div className="col">
           <Q22022 />
          </div>
          <div className="col">
            <Q12023 />
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="column">
          <div className="col col-roadmap-padding-4"></div>
          <div className="col">
            <img src="pirate.png"
              className="Bitmap"
              alt="orange lion" />
          </div>
          <div className="col">
            <Q32022 />
          </div>
          <div className="col">
            <Q242023 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Roadmap;