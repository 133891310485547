import React, { useContext } from 'react';
import { NavContext } from '../../context/NavContext';

const NavLink = ({ navLinkId, scrollToId, mobileScrollToId }) => {
	const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);

	const handleClick = () => {
		setActiveNavLinkId(navLinkId);
		if(window.innerWidth > 768) {
			document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth' });
		} else	{

			document.getElementById(mobileScrollToId).scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<span
			id={navLinkId}
			className={activeNavLinkId === navLinkId ? 'activeClass' : ''}
			onClick={handleClick}
		>
			{navLinkId}
		</span>
	);
};

export default NavLink;