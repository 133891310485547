import React from 'react';
import NavProvider from '../context/NavContext';
import MintNav from './Nav/MintNav';
import MembershipCards from './Shared/MembershipCards';
import AllowList from './MintPage/AllowList';
import Footer from './Shared/Footer';
import '../assets/styles/flexboxgrid.min.css';

const MintPage = ({callback}) => {
  return (
    <>
      <NavProvider>
        <MintNav />
      </NavProvider>
      <div className="container">
        <AllowList callback={callback} />
        <br /><br />
        <MembershipCards displayMintButton={true} />
      </div>
      <Footer></Footer>
    </>
  )
}

export default MintPage;