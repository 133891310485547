const ConnectedStatusBadge = ({ status }) => {
  const statusColour = () => {
    let colour = '';
    if(status === 'Disconnected' || status === 'Not in pre-sale') {
      colour = 'red-badge'
    } else if(status === 'Connected' || status === 'In pre-sale' || status === 'Following' || status === 'Verified user') {
      colour = 'green-badge'
    } else if(status === 'Not verified user' || status === 'In allowlist') {
      colour = 'orange-badge'
    }
    return colour;
  }

  return (
    <>
      <p className={'t-center badge ' + statusColour()}>{status}</p>
    </>
  )
}

export default ConnectedStatusBadge;