const Giveaways = () => {
  return (
    <div className="row component-container" id="giveawayContainer">
      <div className="col-xs-12 col-sm-6">
        <h2 className="left-aligned">Additional Benefits</h2>
        <p className="p-greyish-brown">
          Skippers is launching its first community at the end of 2022. In the meantime we want to try to keep our community active and have some fun.<br /><br />
          So we’ve come up with some tasty benefits that we think will keep everyone interested while we build out the physical community.<br /><br />
          Every Friday at 17h00 CET a week from the drop date a SKIPPERS NFT token's account will be rewarded one of the benefits on the right into their wallet for 45 weeks. See it like a countdown until we open our doors.<br /><br />
        </p>
      </div>
      <div className="col-xs-12 col-sm-6">
        <div className="row">
          <div className="col-xs-12 col-sm-6 padding-2 t-center">
            <img src="kitesurfing-1.png"
              srcSet="kitesurfing-1@2x.png 2x,
                      kitesurfing-1@3x.png 3x"
              className="kitesurfing-1 giveaway-icon"
              alt="kitesurfing icon" /><br/>
            <span className="Unlimited-kitesurf-e p-small-bold-greyish-brown">
              Unlimited kitesurf equipment rental <br />
              <p className="small">x20 holders</p>
            </span>
          </div>
          <div className="col-xs-12 col-sm-6 padding-2 t-center">
            <img src="motorbike.png"
              srcSet="motorbike@2x.png 2x,
                      motorbike@3x.png 3x"
              className="motorbike giveaway-icon"
              alt="motorbike icon" /><br/>
            <span className="Unlimited-kitesurf-e p-small-bold-greyish-brown">
              Unlimited motorbike / eBike usage <br />
              <p className="small">x10 holders</p>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 padding-2 t-center">
            <img src="parachutist-1.png"
              className="parachutist-1 giveaway-icon-parachute"
              alt="parachute icon" /><br/>
            <span className="Unlimited-kitesurf-e p-small-bold-greyish-brown">
              Tandem skydiving credit <br />
              <p className="small">x5 holders</p>
            </span>
          </div>
          <div className="col-xs-12 col-sm-6 padding-2 t-center">
            <img src="hotel-bed-1.png"
              srcSet="hotel-bed-1@2x.png 2x,
                      hotel-bed-1@3x.png 3x"
              className="hotel-bed-1 giveaway-icon-hotel"
              alt="hotel bed icon" /><br/>
            <span className="Unlimited-kitesurf-e p-small-bold-greyish-brown">
              90 days accomodation credit <br />
              <p className="small">x10 holders</p>
            </span>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 pt-0">
        <p>45 weeks represents the maximum amount of time we anticipate it to take before opening the doors of the first community. It will be possible to use the memberships for bookings in advance of the planned opening date. T&Cs apply ask in our Discord for details.</p>
      </div>
    </div>
  )
}

export default Giveaways;