const FrequentlyAskedQuestions = () => {
  return (
    <div id="faqContainer" className="component-container">
      <div className="row">
        <div className="col-xs-12 pb-0">
          <h2 className="left-aligned mb-0">Frequently Asked Questions</h2>
        </div>
        <div className="col-xs-12">
          <h4 className="bold left-aligned">Why the name Skippers?</h4>
          <p className="small">Duncan and Camilla have a backrgound in sailing and their view is that anyone who has taken charge of their life to break away from the mould is a skipper of their own destiny.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 pb-0">
          <h4 className="bold left-aligned">Bookings and Accommodation credits</h4>
          <p className="small">Below is an overview of some frequently asked questions. For full information on member benfits please see our memeber benefit T&Cs.</p>
        </div>
        <div className="col-xs-12 col-sm-6 pt-0">
          <br />
          <p className="bold">How will priority booking work?</p>
          <p className="small">
            We will process member bookings before manual channels and will hold back a propotion of rooms from aggregators like Airbnb for members.<br /><br />
            This will not gaurantee availability at peak times and we will probably not get it 100% right 100% of the time.<br /><br />
            If you are in our members Discord or email list then you will recieve updates regarding availabilty or times that are highly contended.
          </p>
          <br /><br />
          <p className="bold">How can I ensure that someone selling an NFT does not use the accommodation credit before I buy it?</p>
          <p className="small">
            We offer token holders the ability to ‘lock’ the accomodation credit on a 48 hour timelock.<br /><br />
            When you check a token’s availability then you can see if the credit is locked.<br /><br />
            If the holder has requested an unlock you can see how is left until they would be able to use any remaining credits.
          </p>
        </div>
        <div className="col-xs-12 col-sm-6 pt-0">
          <br />
          <p className="bold">How can I check if my NFT has accommodation credit?</p>
          <p className="small">
            To check credit availability just head here and connect your wallet.<br /><br />
            If you are looking at buying an NFT on OpenSea you can check credit by checking the public member ID.
          </p>
          <br /><br />
          <p className="bold">How many people can stay on a credit?</p>
          <p className="small">
            We will be booking on a room basis with a maximum occupancy based on the room type.<br /><br />
            For our first property we will have rooms with Double, Queen and King sized beds for two person occupancy.<br /><br />
            If you would like to share a room with your travel companion that is entirely your choice.
          </p>
          <br /><br />
          <p className="bold">Are the booking credits perpetual?</p>
          <p className="small">
            Any credits issued must be used up within five years of issue and do not renew on an annual basis.<br /><br />
            We may issue additional credits or other benefits as part of future giveaways.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 pb-0">
          <h4 className="bold left-aligned">Food and drinks benefits</h4>
        </div>
        <div className="col-xs-12 col-sm-6 pt-0">
          <p className="bold">Are there any limits to the food and drinks discounts?</p>
          <p className="small">
            The discounts can be used by you and any one other person at any on-site restaurant or bar at any time of day.<br /><br />
            This means that when ordering drinks at the bar for example you will be able to get two at the discounted rate. It will be at the bar manager’s discretion as to whether this priveledge is being abused or whether to help you abuse it.
          </p>
        </div>
        <div className="col-xs-12 col-sm-6 pt-0">
          <p className="bold">What food will be served on site?</p>
          <p className="small">We have some exciting plans for out onsite restaurant(s). The plan is to have a mix of local dishes alongside some western classics. Menu will be dependant per site.</p>
          <br /><br />
          <p className="bold">Can I cook my own food?</p>
          <p className="small">Of course. If you would like to cook your own food then you can use the communal kitchen.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 pb-0">
          <h4 className="bold left-aligned">Activites</h4>
        </div>
        <div className="col-xs-12 col-sm-6 pt-0">
          <p className="bold">I have never kitesurfed before, can I still rent the equipment?</p>
          <p className="small">
            Kitesurfing without training can be very dangerous.<br /><br />
            To rent kite gear you should have an IKO K or equivalent level of kiteboarding. If you don’t have an IKO card one of our team will help you with a quick assessment and we’ll store it on our system.<br /><br />
            While we’re still confirming details, where possible we will offer members beginner sessions on a first come first serve basis.
          </p>
          <br /><br />
          <p className="bold">What protective equipment do you provide?</p>
          <p className="small">
            We’ve rented gear and vehicles in lots of countries. One thing that is consistent is the poor safety gear.<br /><br />
            All our activities have a high standard of safety gear provided in a range of sizes. From small adult, through to small giant.<br /><br />
            For kitesurfing any kiter can use one of our kite helmets or bouyancy aids as desired.<br /><br />
            For vehicles like motorbikes we provide european standard helmets as well as appropriate protective clothing.<br /><br />
            This includes body armour, knee pads and gloves for the more adventurous riders.<br /><br />
            All vehicles will be subject to regular safety checks and any faults correctly repaired.
          </p>
        </div>
        <div className="col-xs-12 col-sm-6 pt-0">
          <p className="bold">I do not have a local driving license can I still use the onsite motor vehicle benefits?</p>
          <p className="small">
            According to Kenyan law, foreigners have 6 months upon arrival in the country before they must convert their foreign driving license into a Kenyan license.<br /><br />
            So if you have a valid endorsment for the vehicle you want to use you should be fine.<br /><br />
            If you do not have a valid endorsement or are ‘a little rusty’ then we can offer lessons, and it should not take more than 2 or 3 hours/sessions for an eager learner to reach a level where they can start cruising around the local paved and unpaved roads.<br /><br />
            These lessons will cost you $20 / hour. We subject to circumstances can also help you attain a local driving license.
          </p>
        </div>
      </div>
    </div>
  )
}

export default FrequentlyAskedQuestions;