import React, { useState } from 'react';

const LandingPageWalletButton = ({connectWallet, currentAccount, isMobileDevice}) => {

  const [onAllowList] = useState(
    JSON.parse(localStorage.getItem('onAllowList') || 'false')
  )

  const buttonText = () => {
    let buttonText = '';
    if(currentAccount) {
      if(onAllowList) {
        buttonText = 'Join pre-sale'
      } else if(!onAllowList) {
        buttonText = 'Complete set-up'
      }
    } else if(!currentAccount) {
      buttonText = 'Connect your Wallet'
    }
    return buttonText;
  }

  const buttonAction = () => {
    if(currentAccount) {
      window.location.href = '/mint';
    } else {
      connectWallet();
    }
  }

  if (isMobileDevice()) {
    const dappUrl = "www.liveskippers.com/mint"; // TODO enter your dapp URL. For example: https://uniswap.exchange. (don't enter the "https://")
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    return (
      <a href={metamaskAppDeepLink}>
        <button className="cta-button connect-wallet-button">
          <h5>{buttonText()}</h5>
        </button>
      </a>
    );
  }

  return (
    <button onClick={buttonAction} className="cta-button connect-wallet-button">
      <h5>{buttonText()}</h5>
    </button>
  )
}

export default LandingPageWalletButton;
