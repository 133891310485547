import React, { useContext, useState } from 'react';
import { NavContext } from '../../context/NavContext';
import NavLink from './NavLink';

const SocialGoodNavLinks = () => {
	const { activeNavLinkId } = useContext(NavContext);
  const [showDropdown, setShowDropdown] = useState(false);

	return (
    <div
      id="dropdownLinkContainer"
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <span
        id={'socialGood'}
        className={activeNavLinkId === 'socialGood' ? 'activeClass' : ''}
      >
        Community
      </span>
      {showDropdown && (
        <div id="dropdownLinks">
          <NavLink key={12} navLinkId={'Values'} scrollToId={'valuesContainer'} mobileScrollToId={'valuesContainer'} />
          <NavLink key={13} navLinkId={'Communities'} scrollToId={'communitiesContainer'} mobileScrollToId={'communitiesContainer'} />
        </div>
      )}
    </div>
	);
};

export default SocialGoodNavLinks;