const Events = () => {
  return (
    <>
      <div className="row component-container" id="eventsContainer">
        <div className="col-xs-12 col-sm-6 pr-2">
          <h2 className="left-aligned">Events</h2>
          <p className="p-greyish-brown">
            We want to balance a calm relaxed environment during the week with adventure filled weekends.<br /><br />
            To achieve this we will run quality live music in our restaurant and food areas in the afternoons on weekends in high-season (Dec-Mar) and (Jun-Sept) closing by 10pm.<br /><br />
            These will consist of season opening and closing parties in co-ordination with other venues and promotors featuring the best of local and contitnent wide music. <br /><br />
            Check out our spotify playlist to get a better feel for the kind of artists we like and our planned music policy.
          </p>
        </div>
        <div className="col-xs-12 col-sm-6 mt-6">
          <div className="row">
            <div className="col-xs-6 col-sm-6 events-container">
              <div className="container-event-info">
                <h4 className="t-center no-margin">Live music</h4>
                <p className="small t-center">Friday & Saturday afternoons</p>
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 events-container">
              <div className="container-event-info">
                <h4 className="t-center no-margin">Jam sessions</h4>
                <p className="small t-center">Sunday brunch</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 col-sm-6 events-container">
              <div className="container-event-info">
                <h4 className="t-center no-margin">Chill sessions</h4>
                <p className="small t-center">Mid-week</p>
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 events-container">
              <div className="container-event-info">
                <h4 className="t-center no-margin">Opening/Closing</h4>
                <p className="small t-center">Start & End of peak season</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-xs-12">
          <p className="bold">Low season</p>
          <p className="p-greyish-brown">The ‘low’ season April-May & Sept-Nov we will still be open but will operate fewer music based events and more member interest driven events including technical, entrepreneur and crypto themes. As a member you can propose and vote on what events we should try to run.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <iframe title="spotify-playlist" className="spotify-playlist" src="https://open.spotify.com/embed/playlist/6nKxPDNqOJH1X08j5Q4eTr?" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
        </div>
      </div>
    </>
  )
}

export default Events;