const Q12022 = () => {
  return (
    <>
      <h5 className="greyish-brown">Q1 2022</h5>
      <ul className="ul-spacing">
        <li>Idea and concept formation <span className="small-oval bg-green"></span></li>
        <li>Team recruitment <span className="small-oval bg-green"></span></li>
        <li>Market and land research <span className="small-oval bg-green"></span></li>
        <li>Economics finalised <span className="small-oval bg-green"></span></li>
        <li>Researching and shortlisting schools for partnership <span className="small-oval bg-green"></span></li>
      </ul>
    </>
  )
}

export default Q12022;
