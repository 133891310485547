const DigitalArt = () => {
  return (
    <div id="artContainer" className="component-container">
      <div className="row">
        <div className="col-xs-12">
          <h2>Digital Art</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-3">
          <img src="wolverine-lion.png"
            className="Bitmap"
            alt="pink lion" />
        </div>
        <div className="col-xs-12 col-sm-6">
          <p className="p-black t-center">
            All members will recieve a unique NFT designed by award winning up and coming Kenyan artist Daniel.<br /><br />
            Each token has 11 unique traits with variations from regular to super-rare. Holders will have full rights over their token art.<br /><br />
            We will generate art for 650 lions, 50% male, 50% female. You can choose at the point of minting which you would prefer.
          </p>
        </div>
        <div className="col-xs-12 col-sm-3">
          <img src="surfboard-lion.png"
            className="Bitmap"
            alt="orange icon" />
        </div>
      </div>
    </div>
  )
}

export default DigitalArt;