import React from 'react';
import './Nav.css';

const MintNav = () => {
	return (
		<>
			<nav>
				<div className="nav-link-menu">
					<div className="logo">
						<a href="/"><img src="logo.png"
						srcSet="logo@2x.png 2x,
										logo@3x.png 3x"
						alt="logo" className="logo-image" /></a>
					</div>
				</div>
			</nav>
		</>
	);
};

export default MintNav;