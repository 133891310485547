const Communities = () => {
  return (
    <div className="row component-container" id="communitiesContainer">
      <div className="col-xs-12 col-sm-6 pr-2">
        <h2 className="left-aligned">Communities</h2>
        <p className="p-greyish-brown">
          All to often the promise or vision doesn’t meet expectations. We want to set a new standard for coliving properties.<br /><br />
          At each property we want to make sure the our guests feel like they have a better than at home experience.<br /><br />
          From comfortable beds and multiple mattress options to air conditioning, on-site restaurants, kitchen and gym facilities we want to make sure we’ve got all your bases covered.<br /><br />
          No more cutting corners.
        </p>
      </div>
      <div className="col-xs-12 col-sm-6 mt-2">
        <div className="row">
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="internet.png"
              srcSet="internet@2x.png 2x,
                      internet@3x.png 3x"
              className="community-icon"
              alt="internet icon" />
            <p className="small t-center">
              Fibre fast internet
            </p>
          </div>
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="towel.png"
              srcSet="towel@2x.png 2x,
                      towel@3x.png 3x"
              className="community-icon"
              alt="towel icon" />
            <p className="small t-center">
              Towels
            </p>
          </div>
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="air-conditioner-1.png"
              srcSet="air-conditioner-1@2x.png 2x,
                      air-conditioner-1@3x.png 3x"
              className="community-icon"
              alt="air conditioner icon" />
            <p className="small t-center">
              In room AC
            </p>
          </div>
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="working-1.png"
              srcSet="working-1@2x.png 2x,
                      working-1@3x.png 3x"
              className="community-icon"
              alt="coworking icon" />
            <p className="small t-center">
              Coworking
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="fridge-1.png"
              srcSet="fridge-1@2x.png 2x,
                      fridge-1@3x.png 3x"
              className="community-icon"
              alt="fridge icon" />
            <p className="small t-center">
              Fridge in room
            </p>
          </div>
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="maid.png"
              srcSet="maid@2x.png 2x,
                      maid@3x.png 3x"
              className="community-icon"
              alt="maid icon" />
            <p className="small t-center">
              Weekly cleaning
            </p>
          </div>
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="kitchen-1.png"
              srcSet="kitchen-1@2x.png 2x,
                      kitchen-1@3x.png 3x"
              className="community-icon"
              alt="kitchen icon" />
            <p className="small t-center">
              Community kitchen
            </p>
          </div>
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="weights-1.png"
              srcSet="weights-1@2x.png 2x,
                      weights-1@3x.png 3x"
              className="community-icon"
              alt="weights icon" />
            <p className="small t-center">
              Gym w/ AC
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="washer-1.png"
              srcSet="washer-1@2x.png 2x,
                      washer-1@3x.png 3x"
              className="community-icon"
              alt="washing machine icon" />
            <p className="small t-center">
              Laundry
            </p>
          </div>
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="fork.png"
              srcSet="fork@2x.png 2x,
                      fork@3x.png 3x"
              className="community-icon"
              alt="fork icon" />
            <p className="small t-center">
              Restaurants
            </p>
          </div>
          <div className="col-xs-6 col-sm-3 mtb-1">
            <img src="swimming.png"
              srcSet="swimming@2x.png 2x,
                      swimming@3x.png 3x"
              className="community-icon"
              alt="swimming icon" />
            <p className="small t-center">
              Pool
            </p>
          </div>
          <div className="col-xs-6 col-sm-3">
          </div>
        </div>
      </div>
    </div>
  )
}

export default Communities;